<template>
  <div>
    <el-dropdown @command="command">
      <span class="el-dropdown-link more">
        <i class="eden-icon-more" />
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :command="'edit'" icon="eden-icon-edit-write"
          >Edit</el-dropdown-item
        >
        <template v-if="showStatusChange">
          <el-dropdown-item
            v-if="!serviceType.is_active"
            icon="el-icon-open"
            :command="'activate'"
            >Activate</el-dropdown-item
          >
          <el-dropdown-item
            v-else
            icon="el-icon-turn-off"
            :command="'deactivate'"
            >Deactivate</el-dropdown-item
          >
        </template>
        <el-dropdown-item icon="eden-icon-delete" :command="'delete'" disabled
          >Delete</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <eden-confirm-dialog
      :title="dialog.title"
      :button-text="dialog.button"
      :button-type="dialog.buttonType"
      :button-status.sync="confirmDialog.status"
      :show.sync="confirmDialog.visibility"
      @confirm="setAction"
    >
      <p>
        {{ dialog.message }}
      </p>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import serviceType from "@/requests/services/service-type";

export default {
  name: "ServiceTypeActions",
  props: {
    serviceType: {
      type: Object,
      default() {
        return {};
      },
    },
    showStatusChange: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      actions: {
        command: "",
        component: "",
        visibility: false,
      },
      confirmDialog: {
        action: "",
        visibility: false,
        status: false,
      },
    };
  },
  computed: {
    dialog() {
      const dialogs = {
        activate: {
          title: "Activate",
          button: "Activate",
          buttonType: "success",
          message: `Are you sure you want to enable ${this.serviceType.service_type}?`,
        },
        deactivate: {
          title: "Deactivate",
          button: "Deactivate",
          buttonType: "danger",
          message: `Are you sure you want to deactivate ${this.serviceType.service_type}?`,
        },
        delete: {
          title: "Delete",
          button: "Delete",
          buttonType: "danger",
          message: `Are you sure you want to delete ${this.serviceType.service_type}?`,
        },
      };
      return dialogs[this.confirmDialog.action]
        ? dialogs[this.confirmDialog.action]
        : dialogs.activate;
    },
  },
  methods: {
    command(command) {
      if (command === "edit") {
        this.$router.push({
          name: "services.service-type.edit",
          params: { id: this.serviceType.id },
        });
      } else {
        this.confirmDialog.action = command;
        this.confirmDialog.visibility = true;
      }
    },
    setAction() {
      this.confirmDialog.status = true;
      switch (this.confirmDialog.action) {
        case "activate":
          this.changeServiceTypeStatus("activate");
          break;
        case "deactivate":
          this.changeServiceTypeStatus("deactivate");
          break;
        case "delete":
          this.deleteServiceType();
          break;
        default:
          break;
      }
    },
    changeServiceTypeStatus(activation_status) {
      serviceType
        .status(this.serviceType.id, { activation_status })
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("updated");
            this.closeConfirmDialog();
          }
        })
        .catch();
    },
    deleteServiceType() {
      const payload = {
        service_type_ids: [this.serviceType.id],
      };
      serviceType
        .delete(this.serviceType.service, payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("updated");
            this.closeConfirmDialog();
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.closeConfirmDialog();
        });
    },
    closeConfirmDialog() {
      this.confirmDialog.status = false;
      this.confirmDialog.visibility = false;
    },
  },
};
</script>
